<!--佣金记录-->
<template>
  <pagination2 :option="search" url="/Wallet/brokerageRecord">
    <template v-slot:other>
      <!--搜索-->
      <div class="search-contain">
        <div class="vm search-item search-item3 mr20">
          <div class="vm select">
            <el-date-picker
              v-model="search.courseTime"
              @change="selectedTime(arguments[0], ['startTime', 'endTime'])"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              unlink-panels
              value-format="timestamp"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </div>
        <div class="vm search-item search-item1">
          <div class="vm select">
            <el-select v-model="search.type">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="vm input search-item-1">
            <el-input
              :placeholder="placeholder"
              v-model="search.search"
              clearable
            ></el-input>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:default="{ tableData }">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333'
        }"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="c_time"
          label="交易时间"
          sortable="custom"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>

        <el-table-column
          label="推广课程"
          prop="course_name"
          empty-text="- -"
          min-width="120"
        ></el-table-column>
        <el-table-column prop="benefits_name" label="推广人" min-width="120">
          <template slot-scope="scope">
            <div class="flex-center user-info">
              <img class="photo" :src="scope.row.benefits_photo" />
              <span class="uname" v-if="!scope.row.benefits_bz">
                {{ scope.row.benefits_name }}
              </span>
              <span v-else class="uname">
                {{ scope.row.benefits_name }}({{ scope.row.benefits_bz }})
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="购买人" prop="buyer_name" min-width="120">
          <template slot-scope="scope">
            <div class="flex-center user-info">
              <img class="photo" :src="scope.row.buyer_photo" />
              <span class="uname" v-if="!scope.row.buyer_bz">
                {{ scope.row.buyer_name }}
              </span>
              <span v-else class="uname">
                {{ scope.row.buyer_name }}({{ scope.row.buyer_bz }})
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="裂变活动"
          prop="fission_name"
          empty-text="- -"
        ></el-table-column>
        <el-table-column
          label="成交金额(元)"
          prop="deal_price"
         min-width="124"
          sortable="custom"
        ></el-table-column>

        <el-table-column
          label="佣金支出(元)"
          sortable="custom"
          prop="brokerage"
          min-width="124"
        >
          <template slot-scope="scope">
            <span class="red">-{{ scope.row.brokerage }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="分成比"
          prop="commission_ratio"
          sortable="custom"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.commission_ratio }}%
          </template>
        </el-table-column>

        <el-table-column
          label="课程实收入(元)"
          sortable="custom"
          prop="real_income"
          min-width="140"
        >
          <template slot-scope="scope">
            <span class="orange">{{ scope.row.real_income }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="80" label="操作" align="right" header-align="right">
          <template slot-scope="scope">
            <router-link
              :to="{
                path: `/capitalOrder/detail?data_id=${scope.row.order_id}`
              }"
            >
              <el-button type="text" size="small">
                查看订单
              </el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </pagination2>
</template>
<script>
export default {
  name: 'CommissionRecord',

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            }
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            }
          }
        ]
      },

      search: {
        type: 1,
        order: '',
        sort: '',
        startTime: '',
        endTime: '',
        courseTime: ''
      },

      // 搜索-
      options1: [
        { label: '课程', value: 1, placeholder: '输入课程名称搜索' },
        {
          label: '推广人',
          value: 2,
          placeholder: '输入推广人昵称/真实姓名搜索'
        },
        {
          label: '购买人',
          value: 3,
          placeholder: '输入购买人昵称/真实姓名搜索'
        },
        { label: '裂变活动', value: 4, placeholder: '输入裂变活动名称搜索' }
      ]
    }
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.type)
        .placeholder
    }
  },

  methods: {
    sortChange(value) {
      this.search = _.assign({}, this.search, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop
      })
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.search[contextName[index]] += 86399
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.flexBox {
  margin: 30px 0;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 !important;
}
.user-info {
  .photo {
   min-width: 20px;
   width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  .uname {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
}
/**搜索**/
.search-contain {
  font-size: 0;
  text-align: right;
}
.search-item {
  margin-bottom: 30px;
  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }
  ::v-deep .search-item-1 {
    .el-input {
      width: 240px;
    }
  }
  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;
    .el-input__inner {
      height: 36px;
    }
    .el-input__icon {
      line-height: 36px;
    }
    .el-date-editor .el-range__icon {
      line-height: 31px;
      display: none;
    }
    .el-range-separator {
      line-height: 29px;
    }
  }
}
::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}
::v-deep .search-item1 {
  .el-select {
    width: 120px;
  }
}
::v-deep .search-item2 {
  .el-input {
    width: 120px;
  }
}
::v-deep .search-item1,
::v-deep .search-item3,
::v-deep .search-item2 {
  .el-input__icon {
    line-height: 27px !important;
  }
}
::v-deep .search-item1 {
  .select {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .input {
    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
