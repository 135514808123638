var render = function render(){var _vm=this,_c=_vm._self._c;return _c('pagination2',{attrs:{"option":_vm.search,"url":"/LiveGoods/goodsOrderList"},scopedSlots:_vm._u([{key:"other",fn:function({ tableData: { find }, total }){return [_c('div',{staticClass:"flexBox"},[_c('flexBox1',{attrs:{"list":_vm.headerData(find, total)}})],1),_vm._t("default"),_c('div',{staticClass:"search-contain"},[_c('div',{staticClass:"vm search-item search-item3 mr10"},[_c('div',{staticClass:"vm select"},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","unlink-panels":"","value-format":"timestamp","picker-options":_vm.pickerOptions},on:{"change":function($event){return _vm.selectedTime(arguments[0], ['start_time', 'end_time'])}},model:{value:(_vm.search.courseTime),callback:function ($$v) {_vm.$set(_vm.search, "courseTime", $$v)},expression:"search.courseTime"}})],1)])])]}},{key:"default",fn:function({ tableData: { list } }){return [_c('el-table',{attrs:{"data":list,"header-cell-style":{
        background: 'rgba(245,245,245,1)',
        color: '#333333',
      }},on:{"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"label":"金额(元)","width":"150px","sortable":"custom","prop":"price"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"orange"},[_vm._v("+"+_vm._s(scope.row.price))])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"name","label":"学员","min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"user-info"},[_c('img',{staticClass:"photo",attrs:{"src":scope.row.uphoto}}),_c('p',{staticClass:"uname",attrs:{"title":scope.row.uname +
                (scope.row.student_remarks
                  ? '(' + scope.row.student_remarks + ')'
                  : '')}},[_vm._v(" "+_vm._s(scope.row.uname)+" "),(scope.row.student_remarks)?_c('span',[_vm._v(" ("+_vm._s(scope.row.student_remarks)+") ")]):_vm._e()])])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"商品","prop":"goods_name"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"tu_name"},[_c('div',{staticClass:"tu"},[_c('img',{attrs:{"src":row.goods_cover_img,"alt":""}})]),_c('div',{staticClass:"name",attrs:{"title":row.goods_name}},[_vm._v(" "+_vm._s(row.goods_name)+" ")])])]}}],null,true)}),_c('el-table-column',{attrs:{"prop":"order_time","label":"交易时间","sortable":"custom"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("formatTimeStamp")(scope.row.order_time,'yyyy-MM-dd hh:mm'))+" ")]}}],null,true)}),_c('el-table-column',{attrs:{"label":"提现状态"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [(row.is_freeze == 1)?_c('div',{staticStyle:{"color":"#FF3F3F"}},[_vm._v(" 不可提现 ")]):(row.is_freeze == 2)?_c('div',{staticStyle:{"color":"#45942C"}},[_vm._v(" 可提现 ")]):_c('div',[_vm._v(" - ")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"操作","align":"right","header-align":"right","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
                path:
                  '/capitalOrder/cashcouponDetail/' + row.live_goods_order_id,
              })}}},[_vm._v(" 查看订单 ")])]}}],null,true)})],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }