<!--充值/赠送记录-->
<template>
  <pagination2 :option="search" url="/Wallet/rechargeList">
    <template v-slot:other="{ tableData: { dingdouInfo } }">
      <div class="flexBox">
        <flexBox1 :list="headerData(dingdouInfo)"></flexBox1>
      </div>
      <!--搜索-->
      <div class="search-contain">
        <div class="vm search-item search-item2 mr20">
          <div class="vm select">
            <el-select v-model="search.type">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="vm search-item search-item3">
          <div class="vm select">
            <el-date-picker
              v-model="search.courseTime"
              @change="selectedTime(arguments[0], ['startTime', 'endTime'])"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              unlink-panels
              value-format="timestamp"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:default="{ tableData: { list } }">
      <el-table
        :data="list"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333'
        }"
        @sort-change="sortChange"
      >
        <el-table-column label="类型" prop="rc_type">
          <template slot-scope="scope">
            {{ scope.row.rc_type | getDataType }}
          </template>
        </el-table-column>

        <el-table-column prop="rc_addTime" label="交易时间" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.rc_addTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>

        <el-table-column label="充值金额(元)" prop="rc_amount">
          <template slot-scope="scope">
            {{ scope.row.rc_type != 4 ? scope.row.rc_amount : '- -' }}
          </template>
        </el-table-column>

        <el-table-column label="充值叮豆(个)" prop="rc_dingdou">
          <template slot-scope="scope">
            {{ scope.row.rc_type != 4 ? scope.row.rc_dingdou : '- -' }}
          </template>
        </el-table-column>

        <el-table-column label="充值赠送(个)" prop="zs_dingdou" width="130">
          <template slot="header">
            <div class="flex-center hidetext">
              充值赠送(个)
              <helpIcon
                style="margin-left:6px;"
                content="充值赠送：指在充值叮豆时获得赠送的叮豆数量当您在充值页看到充多少送多少时，表示充值活动正在进行，充值成功后会赠送对应的数量</br> 具体赠送数量按活动实际赠送数量为主"
              ></helpIcon>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.rc_type == 1 ? scope.row.zs_dingdou : '- -' }}
          </template>
        </el-table-column>

        <el-table-column label="到账叮豆(个)" prop="account_dingdou">
          <template slot-scope="scope">
            <span class="orange">+{{ scope.row.account_dingdou }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="叮豆余额(个)"
          prop="balance_dingdou"
          width="130"
        >
          <template slot="header">
            <div class="flex-center hidetext">
              叮豆余额(个)
              <helpIcon
                style="margin-left:6px;"
                prompt="指每条记录产生后当时剩余的叮豆数量，不是网校实时的叮豆数量，该项数据统计自2020年8月17日后开始记录
"
              ></helpIcon>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.balance_dingdou || '- -' }}
          </template>
        </el-table-column>

        <el-table-column
          label="状态"
          prop="rc_status"
          align="right"
          header-align="right"
        >
          <template slot-scope="scope">
            {{ scope.row.rc_type == 4 ? '赠送成功' : '充值成功' }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </pagination2>
</template>
<script>
import flexBox1 from '@/components/flex/flexBox1'
import helpIcon from '@/components/helpIcon'

export default {
  name: 'RechargeRecord',

  components: {
    flexBox1,
    helpIcon
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            }
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            }
          }
        ]
      },

      search: {
        type: 0,
        order: '',
        sort: '',
        startTime: '',
        endTime: '',
        courseTime: ''
      },

      // 搜索-
      options1: [
        { label: '全部类型', value: 0 },
        { label: '线上充值', value: 1 },
        { label: '线下充值', value: 5 },
        { label: '系统赠送', value: 4 }
      ]
    }
  },

  filters: {
    getDataType(type) {
      let text = ''
      switch (Number(type)) {
        case 1:
          text = '线上充值'
          break
        case 4:
          text = '系统赠送'
          break
        case 5:
          text = '线下充值'
          break
      }

      return text
    }
  },

  methods: {
    sortChange(value) {
      this.search = _.assign({}, this.search, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop
      })
    },

    // 网校的一些基本数据
    headerData(value = {}) {
      return [
        {
          top: '累计充值叮豆(个)',
          bottom: value.rc_dingdou || '0',
          name: Math.random()
        },
        {
          top: '累计充值金额(元)',
          bottom: value.rc_amount || '0.00',
          name: 'tiXian'
        },
        {
          top: '累计充值次数',
          bottom: value.count || '0',
          name: 'dingDou'
        },
        {
          top: '累计充值赠送(个)',
          bottom: value.zs_dingdou || '0',
          name: Math.random()
        },
        {
          top: '累计系统赠送(个)',
          bottom: value.xt_dingdou || '0',
          name: Math.random()
        }
      ]
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.search[contextName[index]] += 86399
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.flexBox {
  margin: 30px 0;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 !important;
}
.user-info {
  .photo {
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
  }
  .uname {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
}
/**搜索**/
.search-contain {
  font-size: 0;
  text-align: right;
}
.search-item {
  margin-bottom: 30px;
  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }
  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;
    .el-input__inner {
      height: 36px;
    }
    .el-input__icon {
      line-height: 36px;
    }
    .el-date-editor .el-range__icon {
      line-height: 31px;
      display: none;
    }
    .el-range-separator {
      line-height: 29px;
    }
  }
}
::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}
::v-deep .search-item1 {
  .el-select {
    width: 120px;
  }
}
::v-deep .search-item2 {
  .el-input {
    width: 120px;
  }
}
::v-deep .search-item1,
::v-deep .search-item3,
::v-deep .search-item2 {
  .el-input__icon {
    line-height: 27px !important;
  }
}
</style>
