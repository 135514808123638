<!--服务开通-->
<template>
  <pagination2 :option="search" url="/Wallet/provisioning">
    <template v-slot:default="{ tableData }">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333'
        }"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="vip_buying_time"
          label="开通时间"
          sortable="custom"
        >
          <template slot-scope="scope">
            {{
              scope.row.vip_buying_time | formatTimeStamp('yyyy-MM-dd hh:mm')
            }}
          </template>
        </el-table-column>

        <el-table-column label="类型" prop="amount">
          <template slot-scope="scope">
            {{ scope.row.version_id | versionText }}开通
          </template>
        </el-table-column>

        <el-table-column
          label="生效时间"
          sortable="custom"
          prop="vip_buying_time"
        >
          <template slot-scope="scope">
            {{
              scope.row.vip_buying_time | formatTimeStamp('yyyy-MM-dd hh:mm')
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="失效时间"
          sortable="custom"
          prop="vip_expiry_time"
        >
          <template slot-scope="scope">
            {{
              scope.row.vip_expiry_time | formatTimeStamp('yyyy-MM-dd hh:mm')
            }}
          </template>
        </el-table-column>

        <el-table-column
          label="期限"
          prop="term"
          align="right"
          header-align="right"
        >
          <template slot-scope="scope">{{ scope.row.term }}天</template>
        </el-table-column>
      </el-table>
    </template>
  </pagination2>
</template>
<script>
export default {
  name: 'ServiceOpening',

  data() {
    return {
      search: {
        status: 0,
        order: '',
        sort: ''
      }
    }
  },

  methods: {
    sortChange(value) {
      this.search = _.assign({}, this.search, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop == 'vip_expiry_time' ? 2 : 1
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.flexBox {
  margin: 30px 0;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 !important;
}
.user-info {
  .photo {
    width: 20px;
    height: 20px;
   min-width: 20px;
    border-radius: 10px;
  }
  .uname {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
}
</style>
