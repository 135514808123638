<!--打赏收入-->
<template>
  <pagination2 :option="search" url="/Wallet/giftOrderList">
    <template v-slot:other="{ tableData: { giftInfo } }">
      <div class="flexBox">
        <flexBox1 :list="headerData(giftInfo)"></flexBox1>
      </div>
      <slot name="default"></slot>
      <!--搜索-->
      <div class="search-contain">
        <div class="vm search-item search-item2 mr10">
          <div class="vm select">
            <el-select v-model="search.status">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="vm search-item search-item3 mr10">
          <div class="vm select">
            <el-date-picker
              v-model="search.courseTime"
              @change="selectedTime(arguments[0], ['startTime', 'endTime'])"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              unlink-panels
              value-format="timestamp"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </div>
        <div class="vm search-item search-item1">
          <div class="vm select">
            <el-select v-model="search.type">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div class="vm input">
            <el-input
              :placeholder="placeholder"
              v-model="search.search"
              clearable
            ></el-input>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:default="{ tableData: { list } }">
      <el-table
        :data="list"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333'
        }"
        @sort-change="sortChange"
      >
        <el-table-column
          label="打赏金额(元)"
          sortable="custom"
          prop="courseprice"
        >
          <template slot-scope="scope">
            <span class="orange">+{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="打赏学员">
          <template slot-scope="scope">
            <div class="flex-center user-info">
              <img class="photo" :src="scope.row.uphoto" />
              <span class="uname" v-if="!scope.row.student_remarks">
                {{ scope.row.uname }}
              </span>
              <span v-else class="uname">
                {{ scope.row.uname }}({{ scope.row.student_remarks }})
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="课程" prop="course_name"></el-table-column>
        <el-table-column label="章节" prop="name"></el-table-column>
        <el-table-column label="类型" prop="type">
          <template slot-scope="scope">
            {{ scope.row.type == 1 ? '打赏' : '送礼' }}
          </template>
        </el-table-column>
        <el-table-column prop="order_time" label="交易时间" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.order_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>
        <el-table-column label="提现状态">
          <template slot-scope="{ row }">
            <div v-if="row.is_freeze == 1" style="color: #FF3F3F">
              不可提现
            </div>
            <div v-else-if="row.is_freeze == 2" style="color: #45942C">
              可提现
            </div>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="上课老师"
          prop="teacher_name"
          align="right"
          header-align="right"
        ></el-table-column>
      </el-table>
    </template>
  </pagination2>
</template>
<script>
import flexBox1 from '@/components/flex/flexBox1'

export default {
  name: 'IncomeCourse',

  components: {
    flexBox1
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            }
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            }
          }
        ]
      },

      search: {
        order: '',
        sort: '',
        startTime: '',
        endTime: '',
        courseTime: '',
        status: 0,
        type: 1,
        search: ''
      },

      // 搜索-
      options1: [
        { label: '全部类型', value: 0 },
        { label: '打赏', value: 1 },
        { label: '送礼', value: 2 }
      ],

      options2: [
        { label: '课程', value: 1, placeholder: '输入课程名称搜索' },
        { label: '章节', value: 2, placeholder: '输入章节名称搜索' },
        { label: '上课老师', value: 3, placeholder: '输入上课老师搜索' },
        {
          label: '学员搜索',
          value: 4,
          placeholder: '输入学员昵称/真实姓名搜索'
        }
      ]
    }
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options2.find(item => item.value == this.search.type)
        .placeholder
    }
  },

  methods: {
    sortChange(value) {
      this.search = _.assign({}, this.search, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop == 'courseprice' ? 1 : 2
      })
    },

    // 网校的一些基本数据
    headerData(value = {}) {
      return [
        {
          top: '累计打赏收入(元)',
          bottom: value.price || '0.00',
          name: Math.random(),
          prompt:
            '指学员在直播课累计打赏送礼的收入，包括已删除、已下架和已隐藏的课程'
        },
        {
          top: '累计打赏次数(次)',
          bottom: value.count || '0.00',
          name: 'tiXian',
          prompt:
            '指学员在直播课累计打赏送礼的次数，重复打赏送礼算多次；包括已删除、已下架和已隐藏的课程'
        },
        {
          top: '累计打赏人数(人)',
          bottom: value.number || '0.00',
          name: 'dingDou',
          prompt:
            '指在直播间打赏送礼过的学员数量，重复打赏送礼算一人，包括已拉黑的学员'
        },
        {
          top: '累计打赏课程(门)',
          bottom: value.course_number || '0.00',
          name: Math.random(),
          prompt:
            '指被学员打赏过送礼的直播课程数量，包括已删除、已下架、已隐藏的课程'
        }
      ]
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.search[contextName[index]] += 86399
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.flexBox {
  margin: 30px 0;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 !important;
}
.user-info {
  .photo {
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 10px;
  }
  .uname {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
}
/**搜索**/
.search-contain {
  font-size: 0;
  text-align: right;
}
.search-item {
  margin-bottom: 30px;
  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }
  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;
    .el-input__inner {
      height: 36px;
    }
    .el-input__icon {
      line-height: 36px;
    }
    .el-date-editor .el-range__icon {
      line-height: 31px;
      display: none;
    }
    .el-range-separator {
      line-height: 29px;
    }
  }
}
::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}
::v-deep .search-item1 {
  .el-select {
    width: 120px;
  }
}
::v-deep .search-item2 {
  .el-input {
    width: 120px;
  }
}
::v-deep .search-item1,
::v-deep .search-item3,
::v-deep .search-item2 {
  .el-input__icon {
    line-height: 27px !important;
  }
}
::v-deep .search-item1 {
  .select {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .input {
    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
