<template>
  <div class="scholl-account">
    <div class="top-contain">
      <flexBox1 :list="schoolAccountData">
        <template v-slot:dingDou>
          <el-button
            v-if="showRechargeBtn"
            class="mt20"
            type="primary"
            size="mini"
            @click="recharge"
          >
            充值
          </el-button>
        </template>
        <template v-slot:tiXian>
          <el-button
            v-if="showExtractMoneyBtn"
            class="mt20"
            type="primary"
            size="mini"
            @click="extractMoney"
          >
            提现
          </el-button>
        </template>
      </flexBox1>
    </div>
    <!--切换菜单-->
    <div class="table-contain">
      <el-tabs v-model="componentActive">
        <el-tab-pane
          v-for="(item, index) in component"
          :key="index"
          :label="item.label"
          :name="item.id"
        ></el-tab-pane>
        <div class="tab-components">
          <component :is="componentActive"></component>
        </div>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import bg from '@/mixin/background'
import flexBox1 from '@/components/flex/flexBox1'
import IncomeRecord from './components/IncomeRecord'
import DeductionRecord from './components/DeductionRecord'
import RechargeRecord from './components/RechargeRecord'
import WithdrawalsRecord from './components/WithdrawalsRecord'
import ServiceOpening from './components/ServiceOpening'
import CommissionRecord from './components/CommissionRecord'

export default {
  mixins: [bg],

  components: {
    flexBox1,
    IncomeRecord,
    DeductionRecord,
    RechargeRecord,
    WithdrawalsRecord,
    ServiceOpening,
    CommissionRecord,
  },
  data() {
    return {
      componentActive: this.$route.query.name || 'IncomeRecord',
      component: [
        { label: '收入记录', id: 'IncomeRecord' },
        { label: '扣费记录', id: 'DeductionRecord' },
        { label: '充值/赠送记录', id: 'RechargeRecord' },
        { label: '提现记录', id: 'WithdrawalsRecord' },
        { label: '服务开通', id: 'ServiceOpening' },
        { label: '裂变海报佣金记录', id: 'CommissionRecord' },
      ],

      baseValue: {},
    }
  },

  computed: {
    // 网校的一些基本数据
    schoolAccountData() {
      return [
        {
          top: '累计总收入(元)',
          bottom: this.baseValue.total_income || '0.00',
          name: Math.random(),
        },
        {
          top: '冻结金额(元)',
          bottom: this.baseValue.frozen_price || '0.00',
          name: Math.random(),
          prompt:'t+7周期内的订单金额，结算周期后会自动划转到可提现金额',
        },
        {
          top: '可提现金额(元)',
          bottom: this.baseValue.cash_available || '0.00',
          name: 'tiXian',
        },
        {
          top: '叮豆(个)',
          bottom: this.baseValue.t_amount || '0',
          name: 'dingDou',
        },
        {
          top: '打赏收入(元)',
          bottom: this.baseValue.gift_price || '0.00',
          name: Math.random(),
        },
        {
          top: '裂变海报佣金支出(元)',
          bottom: this.baseValue.brokerage || '0',
          name: Math.random(),
        },
      ]
    },

    showRechargeBtn() {
      return this.$store.state.roots.includes(138)
    },

    showExtractMoneyBtn() {
      return this.$store.state.roots.includes(137)
    },
  },

  created() {
    if (this.$route.query.type) {
      this.componentActive = 'DeductionRecord'
    }
    this.getAssetData()
  },

  methods: {
    // 获取机构资产
    getAssetData() {
      this.$http({
        name: 'assetInfo',
        url: '/Wallet/baseInfo',
        callback: ({ data }) => {
          this.baseValue = data
        },
      })
    },

    // 跳转提现页面
    extractMoney() {
      // 判断是否可提现
      if (this.baseValue.cash_available >= 500) {
        this.$router.push({
          path: '/capitalAccount/TiXian',
          params: {
            canTiXianPrice: this.baseValue.cash_available,
          },
        })
      } else {
        this.$root.prompt('金额需大于等于500元才可提现')
      }
    },

    // 跳转充值页面
    recharge() {
      this.$router.push({
        path: '/capitalAccount/TopUpDingDou',
      })
    },

    //测试详情使用
    // recharge() {
    //   this.$router.push({
    //     path: '/capitalAccount/detail'
    //   })
    // }
  },
}
</script>
<style lang="scss" scoped>
.scholl-account {
  margin: 20px;
  background: #fff;
  padding-top: 30px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 20px;
}
.top-contain {
  padding: 0 20px;
  text-align: center;
}
.table-contain {
  margin-top: 46px;
  padding-bottom: 20px;
}
.tab-components {
  padding: 0 20px;
}
</style>
