<!--提现记录-->
<template>
  <pagination2 :option="search" url="/Wallet/withdrawalRecord">
    <template v-slot:other="{ tableData }">
      <div class="flexBox">
        <flexBox1 :list="headerData(tableData)"></flexBox1>
      </div>
      <!--搜索-->
      <div class="search-contain">
        <div class="vm search-item search-item2 mr20">
          <div class="vm select">
            <el-select v-model="search.status">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="vm search-item search-item3">
          <div class="vm select">
            <el-date-picker
              v-model="search.courseTime"
              @change="selectedTime(arguments[0], ['startTime', 'endTime'])"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              unlink-panels
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:default="{ tableData: { list } }">
      <el-table
        :data="list"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333'
        }"
        @sort-change="sortChange"
      >
        <el-table-column
          prop="dr_time"
          label="交易时间"
          sortable="custom"
          width="140"
        >
          <template slot-scope="scope">
            {{ scope.row.dr_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>

        <el-table-column label="提现金额(元)" prop="amount" width="130">
          <template slot-scope="scope">
            <div class="red">-{{ scope.row.amount || '- -' }}</div>
          </template>
        </el-table-column>

        <el-table-column label="手续费(元)" prop="service_charge" width="110">
          <template slot="header">
            <div class="flex-center hidetext">
              手续费(元)
              <!-- <helpIcon
                style="margin-left:6px;"
                prompt="提现是由公户转账，银行系统会收取3%的手续费"
              ></helpIcon> -->
            </div>
          </template>
          <template slot-scope="scope">
            <div class="red">-{{ scope.row.service_charge || '- -' }}</div>
          </template>
        </el-table-column>

        <el-table-column label="费率" prop="commission" width="60">
          <template slot-scope="scope">
            <div class="red">{{ Number(scope.row.commission)*100+'%' || '- -' }}</div>
          </template>
        </el-table-column>

        <el-table-column label="到账金额(元)" prop="account" width="130">
          <template slot-scope="scope">
            <div class="orange">{{ scope.row.account || '- -' }}</div>
          </template>
        </el-table-column>

        <el-table-column label="提现卡号" prop="bank_account"></el-table-column>
        <el-table-column label="开户银行" prop="open_bank"></el-table-column>
        <el-table-column label="开户人" prop="account_name"></el-table-column>
        <el-table-column label="提现人" prop="uname"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            {{ scope.row.status | getStatus }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </pagination2>
</template>
<script>
import flexBox1 from '@/components/flex/flexBox1'
// import helpIcon from '@/components/helpIcon'

export default {
  name: 'WithdrawalsRecord',

  components: {
    flexBox1,
    // helpIcon
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            }
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            }
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            }
          }
        ]
      },

      search: {
        status: 0,
        order: '',
        sort: '',
        startTime: '',
        endTime: '',
        courseTime: ''
      },

      // 搜索-
      options1: [
        { label: '全部状态', value: 0 },
        { label: '已审核', value: 2 },
        { label: '审核中', value: 1 },
        { label: '已拒绝', value: 3 }
      ]
    }
  },

  filters: {
    getStatus(type) {
      let text = ''
      switch (Number(type)) {
        case 1:
          text = '审核中'
          break
        case 2:
          text = '已审核'
          break
        case 3:
          text = '已拒绝'
          break
      }

      return text
    }
  },

  methods: {
    sortChange(value) {
      this.search = _.assign({}, this.search, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop
      })
    },

    // 网校的一些基本数据
    headerData(value = {}) {
      return [
        {
          top: '累计提现金额(元)',
          bottom: value.sum_amount || '0.00',
          name: Math.random(),
          prompt: '指累计申请提现的金额，包括已审核、审核中、已拒绝的金额'
        },
        {
          top: '累计到账金额(元)',
          bottom: value.sum_account || '0.00',
          name: 'tiXian',
          prompt: '指累计提现到账的金额，不包括审核中、已拒绝的金额'
        },
        {
          top: '累计提现次数',
          bottom: value.all_count || '0',
          name: 'dingDou',
          prompt: '指累计提现的次数，包括已审核、审核中、已拒绝的次数'
        }
      ]
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.search[contextName[index]] += 86399
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.flexBox {
  margin: 30px 0;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 !important;
}
.user-info {
  .photo {
    width: 20px;
    height: 20px;
   min-width: 20px;
    border-radius: 10px;
  }
  .uname {
    font-size: 14px;
    line-height: 19px;
    margin-left: 10px;
    color: rgba(51, 51, 51, 1);
  }
}
/**搜索**/
.search-contain {
  font-size: 0;
  text-align: right;
}
.search-item {
  margin-bottom: 30px;
  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }
  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;
    .el-input__inner {
      height: 36px;
    }
    .el-input__icon {
      line-height: 36px;
    }
    .el-date-editor .el-range__icon {
      line-height: 31px;
      display: none;
    }
    .el-range-separator {
      line-height: 29px;
    }
  }
}
::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}
::v-deep .search-item1 {
  .el-select {
    width: 120px;
  }
}
::v-deep .search-item2 {
  .el-input {
    width: 120px;
  }
}
::v-deep .search-item1,
::v-deep .search-item3,
::v-deep .search-item2 {
  .el-input__icon {
    line-height: 27px !important;
  }
}
</style>
