<!--收入记录-->
<template>
  <div>
    <!--list-->
    <component :is="componentName">
      <template v-slot:default>
        <!--菜单-->
        <div class="tab-contain">
          <el-tabs v-model="pageType" type="card">
            <el-tab-pane
              v-for="item in tabs"
              :key="item.id"
              :label="item.label"
              :name="item.id"
            ></el-tab-pane>
          </el-tabs>
        </div>
      </template>
    </component>
  </div>
</template>
<script>
import graphicRecord from './graphicRecord'
import IncomeReward from './IncomeReward'
import IncomeCourse from './IncomeCourse'
import membershipRecord from './membershipRecord'
import workbookRecord from './workbookRecord'
import opencourseReward from './opencourseReward'
import cashcouponReward from './cashcouponReward'
import commerce from './commerce'
import orderfromData from '@cm/base/orderfromData'

export default {
  name: 'IncomeRecord',

  components: {
    IncomeReward,
    IncomeCourse,
    membershipRecord,
    workbookRecord,
    opencourseReward,
    cashcouponReward,
    orderfromData,
    commerce,
    graphicRecord,
  },

  data() {
    return {
      pageType: '1',

      tabs: [
        { label: '课程', id: '1' },
        { label: '打赏', id: '2' },
        { label: '会员', id: '3' },
        { label: '电子资料', id: '4' },
        { label: '作业', id: '5' },
        { label: '公开课', id: '6' },
        { label: '代金券', id: '7' },
        { label: '商品', id: '8' },
        { label: '图文', id: '9' },
      ],
    }
  },

  computed: {
    componentName() {
      let pageType = ''
      switch (Number(this.pageType)) {
        case 1:
          pageType = 'IncomeCourse'
          break
        case 2:
          pageType = 'IncomeReward'
          break
        case 3:
          pageType = 'membershipRecord'
          break
        case 4:
          pageType = 'orderfromData'
          break
        case 5:
          pageType = 'workbookRecord'
          break
        case 6:
          pageType = 'opencourseReward'
          break
        case 7:
          pageType = 'cashcouponReward'
          break
        case 8:
          pageType = 'commerce'
          break
        case 9:
          pageType = 'graphicRecord'
          break
      }
      return pageType
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .tab-contain {
  margin: 0 0 20px;
  .el-tabs__item {
    width: 97px;
    text-align: center;
  }
  .el-tabs__nav-wrap {
    padding: 0;
  }
}
::v-deep .contentVideo {
  padding: 0;
  .rightMenu {
    padding: 0;
  }
}
</style>
