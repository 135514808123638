<!--扣费记录-->
<template>
  <pagination2 :option="search" url="/Wallet/deductionRecord">
    <template v-slot:other="{ tableData: { info } }">
      <div class="flexBox">
        <flexBox1 :list="headerData(info)"></flexBox1>
      </div>
      <!--搜索-->
      <div class="search-contain">
        <div class="vm search-item search-item2 mr20">
          <div class="vm select">
            <el-select v-model="search.type">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="vm search-item search-item3">
          <div class="vm select">
            <el-date-picker
              v-model="search.courseTime"
              @change="selectedTime(arguments[0], ['startTime', 'endTime'])"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right"
              unlink-panels
              value-format="timestamp"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:default="{ tableData: { list } }">
      <el-table
        :data="list"
        :header-cell-style="{
          background: 'rgba(245,245,245,1)',
          color: '#333333',
        }"
        @sort-change="sortChange"
      >
        <el-table-column prop="c_time" label="结算时间" sortable="custom">
          <template slot="header">
            <div
              class="flex-center hidetext"
              style="float: left; padding-top: 5px"
            >
              结算时间
              <helpIcon
                style="margin-left: 6px"
                prompt="系统结算时间为次日凌晨"
              ></helpIcon>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
          </template>
        </el-table-column>

        <el-table-column label="扣费类型" prop="type">
          <template slot-scope="scope">
            {{ scope.row.type | getCourseType }}
          </template>
        </el-table-column>

        <el-table-column label="消耗叮豆(个)" prop="amount" sortable="custom">
          <template slot-scope="scope">
            <div class="red">-{{ scope.row.amount || '- -' }}</div>
          </template>
        </el-table-column>

        <el-table-column label="叮豆余额(个)" prop="balance_dingdou">
          <template slot="header">
            <div class="flex-center hidetext">
              叮豆余额(个)
              <helpIcon
                style="margin-left: 6px"
                prompt="指每条记录结算后当时剩余的叮豆数量，不是网校实时的叮豆数量，该项数据统计自2020年8月17日后开始记录"
              ></helpIcon>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.balance_dingdou || '- -' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="right" header-align="right">
          <template slot-scope="scope">
            <router-link
              :to="{
                path: `/capitalAccount/detail?sr_id=${scope.row.sr_id}&type=${scope.row.type}`,
              }"
            >
              <el-button type="text" size="small">详情</el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </pagination2>
</template>
<script>
import flexBox1 from '@/components/flex/flexBox1'
import helpIcon from '@/components/helpIcon'

export default {
  name: 'DeductionRecord',

  components: {
    flexBox1,
    helpIcon,
  },

  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              picker.$emit('pick', [+newDate, +newDate])
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = new Date(newDate - 3600 * 1000 * 24)
              picker.$emit('pick', [startTime, startTime])
            },
          },
          {
            text: '近7天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 7
              picker.$emit('pick', [startTime, +newDate])
            },
          },
          {
            text: '近30天',
            onClick(picker) {
              const date = new Date()
              const newDate = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
              )
              const startTime = newDate - 3600 * 1000 * 24 * 30
              picker.$emit('pick', [startTime, +newDate])
            },
          },
        ],
      },

      search: {
        type: 0,
        sort: '',
        startTime: '',
        endTime: '',
        courseTime: '',
      },

      // 搜索-
      options1: [
        { label: '全部类型', value: 0 },
        { label: '直播课-普通模式', value: 6 },
        { label: '直播课-极速模式', value: 7 },
        { label: '小班课', value: 2 },
        { label: '录播课', value: 8 },
        { label: '回放', value: 9 },
        { label: '短信验证', value: 10 },
        { label: '视频转码', value: 11 },
        { label: '直播-公开课', value: 12 },
        { label: '公开课回放', value: 13 },
        { label: '直播-智能直播', value: 14 },
        { label: '智能直播回放', value: 15 },
        { label: '连麦互动', value: 16 },
        { label: '营销短信', value: 17 },
      ],
    }
  },

  filters: {
    getCourseType(type) {
      let text = ''
      //  2 小班   6普通直播    7急速   8录播  9回放   12 公开课直播  13 公开课回放
      switch (Number(type)) {
        case 2:
          text = '小班课'
          break
        case 6:
          text = '直播课-普通模式'
          break
        case 7:
          text = '直播课-极速模式'
          break
        case 8:
          text = '录播课'
          break
        case 9:
          text = '回放'
          break
        case 10:
          text = '短信验证'
          break
        case 11:
          text = '视频转码'
          break
        case 12:
          text = '直播-公开课'
          break
        case 13:
          text = '公开课回放'
          break
        case 14:
          text = '直播-智能直播'
          break
        case 15:
          text = '智能直播回放'
          break
        case 16:
          text = '连麦互动'
          break
        case 17:
          text = '营销短信'
          break
      }

      return text
    },
  },

  methods: {
    sortChange(value) {
      this.search = _.assign({}, this.search, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop,
      })
    },

    // 网校的一些基本数据
    headerData(value = {}) {
      return [
        {
          top: '累计消耗叮豆(个)',
          bottom: value.all_num || '0',
          name: Math.random(),
        },
        {
          top: '直播课消耗(个)',
          bottom: value.live_all_num || '0',
          name: 'tiXian',
          prompt2: `直播课-普通模式(个)：${value.live_num}<br/>直播课-极速模式(个)：${value.fast_live_num}`,
        },
        {
          top: '小班课消耗(个)',
          bottom: value.private_num || '0',
          name: 'dingDou',
        },
        {
          top: '录播课消耗(个)',
          bottom: value.video_num || '0',
          name: Math.random(),
          prompt: '该项数据统计自2020年8月17日后开始记录',
        },
        {
          top: '回放消耗(个)',
          bottom: value.playback_num || '0',
          name: Math.random(),
          prompt: '该项数据统计自2020年8月17日后开始记录',
        },
        // {
        //   top: '短信消耗(个)',
        //   bottom: value.collection_phone_num || '0',
        //   name: Math.random(),
        // },

        // <br/> 营销短信：${value.live_rtc_deduction_num}
        {
          top: '其他消耗(个)',
          bottom: value.other_all_num || '0',
          name: Math.random(),
          prompt2: `短信验证消耗(个)：${value.collection_phone_num}
          <br/>视频转码消耗(个)：${value.encryption_deduction_num} 
          <br/>直播-公开课：${value.open_class_deduction_num}
          <br/>公开课回放：${value.open_class_playback_deduction_num}
          <br/>直播-智能直播：${value.ai_live_deduction_num}
          <br/> 智能直播回放：${value.ai_live_playback_deduction_num}
          <br/> 连麦互动：${value.live_rtc_deduction_num}
          `,
        },
      ]
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000

        // 结束时间加到这天的最后一秒
        if (index == 1) {
          this.search[contextName[index]] += 86399
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.flexBox {
  margin: 30px 0;
}
::v-deep .el-tabs__nav-wrap {
  padding: 0 !important;
}
/**搜索**/
.search-contain {
  font-size: 0;
  text-align: right;
}
.search-item {
  margin-bottom: 30px;
  .middle-space {
    font-size: 14px;
    line-height: 19px;
    margin: 0 10px;
    color: rgba(51, 51, 51, 1);
  }
  ::v-deep .el-input,
  ::v-deep .select {
    height: 36px;
    .el-input__inner {
      height: 36px;
    }
    .el-input__icon {
      line-height: 36px;
    }
    .el-date-editor .el-range__icon {
      line-height: 31px;
      display: none;
    }
    .el-range-separator {
      line-height: 29px;
    }
  }
}
::v-deep .search-item9 .select,
::v-deep .search-item9 .el-input {
  height: auto;
  min-height: 36px;
}
::v-deep .search-item1 {
  .el-select {
    width: 120px;
  }
}
::v-deep .search-item2 {
  .el-input {
    width: 160px;
  }
}
::v-deep .search-item1,
::v-deep .search-item3,
::v-deep .search-item2 {
  .el-input__icon {
    line-height: 27px !important;
  }
}
</style>
