<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-02 18:41:04
 * @LastEditTime: 2022-09-06 16:05:25
 * @LastEditors: cyy
 * @Description: 图文订单
 * @FilePath: \teacherdingding.com\src\views\AssetAdmin\components\graphicRecord.vue
-->
<template>
  <div class="graphicRecord">
    <pagination2
      :option="search"
      class="childDialogBox"
      url="/Graphic/buyOrder"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData: { find, list } }">
        <div class="flexBox">
          <flexBox1 :list="headerData(find)"></flexBox1>
        </div>
        <slot name="default"></slot>
        <!-- <div class="tip">
          <span class="mr20">累计订单收入(元)：{{ find.price }}</span>
          <span>累计订单(笔)：{{ find.count }}</span>
        </div> -->
        <div class="search">
          <el-date-picker
            style="width:360px"
            v-model="search.order_time"
            @change="
              selectedTime(arguments[0], ['start_order_time', 'end_order_time'])
            "
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            unlink-panels
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            size="medium"
          ></el-date-picker>
        </div>

        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          @sort-change="sortChange"
          :data="list"
        >
          <el-table-column label="金额" prop="price" sortable="custom">
            <template slot-scope="{ row }">
              <span style="color:#F39700">+{{ row.price }}</span>
            </template>
          </el-table-column>

          <el-table-column label="学员" min-width="100px">
            <template slot-scope="{ row }">
              <div class="user_contain">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name">
                  {{ row.uname }}
                  <span v-if="row.student_remarks">
                    ({{ row.student_remarks }})
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="图文">
            <template slot-scope="{ row }">
              <div class="graphtic_contain">
                <div class="tu">
                  <img :src="row.cover_img" alt="" />
                </div>
                <div class="name hideText " :title="row.name">
                  {{ row.name }}
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="交易时间" sortable="custom" prop="order_time">
            <template slot-scope="{ row }">
              {{ row.order_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>

          <el-table-column label="提现状态">
            <template slot-scope="{ row }">
              <div v-if="row.is_freeze == 1" style="color: #FF3F3F">
                不可提现
              </div>
              <div v-else-if="row.is_freeze == 2" style="color: #45942C">
                可提现
              </div>
              <div v-else>
                -
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" min-width="100px">
            <template slot-scope="{ row }">
              <el-button
                type="text"
                @click="
                  $router.push({
                    path: '/capitalOrder/graDetails',
                    query: { id: row.g_order_id },
                  })
                "
              >
                查看订单
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>
  </div>
</template>

<script>
// import formFields from './formFields'
import flexBox1 from '@/components/flex/flexBox1'
export default {
  name: 'graphicRecord',

  components: {
    flexBox1,
  },

  data() {
    return {
      fieldsList: [],

      search: {
        search: '',
        order_time: [],
        search_type: 1,
        start_order_time: '',
        end_order_time: '',
        status: 2,
        order: '',
        sort: '',
      },

      search2: {},

      // 搜索-
      options1: [
        { label: '用户名', value: 1, placeholder: '输入用户名' },
        { label: '手机号', value: 2, placeholder: '输入手机号' },
      ],

      options2: [
        { label: '全部', value: 0 },
        { label: '待审核', value: 1 },
        { label: '已拒绝', value: 2 },
      ],
    }
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.search_type)
        .placeholder
    },
  },

  created() {
    this.search2 = _.assign({}, this.search, { status: 2 })
  },

  methods: {
    sortChange(value) {
      this.search = _.assign({}, this.search, {
        sort: !value.order ? '' : value.order === 'ascending' ? 1 : 2,
        order: !value.order ? '' : value.prop == 'price' ? 1 : 2,
      })
    },

    // 网校的一些基本数据
    headerData(value = {}) {
      return [
        {
          top: '累计收入(元)',
          bottom: value.price || '0.00',
          name: Math.random(),
        },
      ]
    },
    // 搜索
    toSearch() {
      this.search2 = _.assign({}, this.search)
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.graphicRecord {
  padding: 0 20px 20px;
  .flexBox {
    margin: 30px 0;
  }
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .childDialogBox {
    .graphtic_contain {
      display: flex;
      align-items: center;
      .tu {
        flex-shrink: 0;
        width: 53px;
        height: 30px;
        background: #dedede;
        margin-right: 10px;
        img {
          height: 100%;
          width: 100%;
        }
      }
      .name {
        flex: 1;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
      }
    }
    .tip {
      font-size: 14px;
      color: #666666;
      margin-bottom: 20px;
    }
    .user_contain {
      display: flex;
      align-content: center;
      .tu {
        width: 28px;
        height: 28px;
        background: #939191;
        border-radius: 14px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}
</style>
